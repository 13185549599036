import React from "react"
import SEO from "components/seo"
import ClientsCarousel from "components/clients"
import GitHubGeneral from "images/GitHub_General_Comment.png"
import MermaidDiagram from "images/Mermaid_Diagram.png"
import GitHubComplexity from "images/GitHub_Complexity.png"
import GitHubVideo from "images/GitHub_Code_Review.mp4"
import AnchorButton from "components/anchor-button"
import IntegrationLogos from "components/integrations"

const CTASection = () => (
  <div className="flex flex-row gap-4 max-w-sm">
    <AnchorButton
      href={process.env.SOURCERY_DASHBOARD_URL}
      className="w-full px-6 py-3 text-center"
    >
      Start a free trial
    </AnchorButton>
    <AnchorButton
      href="https://calendly.com/tim-gilboy/30min"
      className="w-full px-6 py-3 text-center"
    >
      Talk to a founder
    </AnchorButton>
  </div>
)

const IndexPage = () => (
  <div>
    <SEO
      title="Sourcery | Instant Code Review for Faster Velocity"
      description="Sourcery reviews all of the changes to your code and gives you human-like reviews in seconds"
      keywords={[
        `sourcery`,
        `code review`,
        `developer tools`,
        `python`,
        "javascript",
        "typescript",
        `ai`,
      ]}
    />

    <div className="bg-black overflow-hidden pt-[88px] md:pt-[96px]">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between pt-16 pb-12">
          {/* Left side - Text and CTAs */}
          <div className="w-full md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <h1 className="text-5xl md:text-5xl xl:text-7xl font-bold text-white mb-6">
              1000x faster code reviews
            </h1>
            <p className="text-lg md:text-xl text-white mb-8 max-w-2xl">
              Sourcery is an AI code reviewer that finds bugs, improves quality,
              and shares knowledge. Get instant, actionable feedback across 30+
              languages in GitHub, GitLab, or your IDE.
            </p>
            <CTASection />
          </div>

          {/* Right side - Video */}
          <div className="w-full md:w-1/2">
            <div className="rounded-xl overflow-hidden shadow-3xl">
              <video
                src={GitHubVideo}
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                alt="Feature Highlight"
                className="w-full h-auto rounded-2xl"
              />
            </div>
          </div>
        </div>

        {/* Trusted by section */}
        <div className="py-8 px-4">
          <ClientsCarousel />
        </div>
      </div>

      {/* Features Section */}
      <div className="mx-auto max-w-7xl pt-6 pb-6">
        {/* Feature 1 */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 py-8 rounded-lg p-6">
          <div className="md:w-1/2 w-full text-left pr-6 mb-6">
            <h3 className="text-3xl font-bold text-white mb-4">
              Catch critical issues early
            </h3>
            <div>
              <ul className="list-inside list-disc">
                <li className="text-xl text-white">
                  Identify bugs and security issues immediately
                </li>
                <li className="text-xl text-white">
                  Get in-line code suggestions and improvements on every PR
                </li>
                <li className="text-xl text-white">
                  Works directly in your IDE to shift reviews all the way left
                </li>
              </ul>
            </div>
          </div>
          <div className="md:w-1/2 w-full">
            <img
              src={GitHubGeneral}
              alt="Feature Highlight"
              className="rounded-2xl shadow-xl max-w-full h-auto align-middle"
            />
          </div>
        </div>

        {/* Feature 2 */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 py-8 rounded-lg p-6">
          <div className="md:w-1/2 w-full text-left pr-6 mb-6">
            <h3 className="text-3xl font-bold text-white mb-4">
              Accelerate Knowledge Sharing
            </h3>
            <div>
              <ul className="list-inside list-disc">
                <li className="text-xl text-white">
                  Get summaries of every change in a pull request
                </li>
                <li className="text-xl text-white">
                  Review guides explain changes with generated diagrams
                </li>
                <li className="text-xl text-white">
                  Enforce your team&apos;s style guides and best practices
                </li>
              </ul>
            </div>
          </div>
          <div className="md:w-1/2 w-full">
            <img
              src={MermaidDiagram}
              alt="Feature Highlight"
              className="rounded-2xl shadow-xl max-w-full h-auto align-middle"
            />
          </div>
        </div>

        {/* Feature 3 */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-4 mb-8 py-8 rounded-lg p-6">
          <div className="md:w-1/2 w-full text-left pr-4 mb-6">
            <h3 className="text-3xl font-bold text-white mb-4">
              Continuous Learning & Improvement
            </h3>
            <div>
              <ul className="list-inside list-disc">
                <li className="text-xl text-white">
                  Sourcery learns from previous reviews to make better comments
                </li>
                <li className="text-xl text-white">
                  Sourcery adapts to your feedback
                </li>
                <li className="text-xl text-white">
                  Fine-tune review criteria based on your preferences
                </li>
              </ul>
            </div>
          </div>
          <div className="md:w-1/2 w-full">
            <img
              src={GitHubComplexity}
              alt="Feature Highlight"
              className="rounded-2xl shadow-xl max-w-full h-auto align-middle"
            />
          </div>
        </div>

        {/* Enterprise Security Section */}
        <div className="flex flex-col items-center justify-between mb-24">
          <div className="w-full text-center mb-16">
            <h3 className="text-3xl font-bold text-white mb-6">
              Enterprise-Grade Security
            </h3>
          </div>

          {/* Security Feature Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 w-full max-w-5xl mb-8">
            {/* Data & Code Security Card */}
            <div className="border border-white/20 rounded-2xl p-8">
              <div className="flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-white" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 2.18l7 3.12v4.7c0 4.83-2.72 8.93-7 10.33-4.28-1.4-7-5.5-7-10.33V6.3l7-3.12z"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-bold text-white mb-6 text-center">
                Data & Code Security
              </h4>
              <ul className="space-y-4">
                <li className="text-white flex items-start">
                  <span className="text-orange-light mr-4">•</span>
                  Sourcery never stores your code
                </li>
                <li className="text-white flex items-start">
                  <span className="text-orange-light mr-4">•</span>
                  LLM providers never train models on your code
                </li>
                <li className="text-white flex items-start">
                  <span className="text-orange-light mr-4">•</span>
                  Zero retention options available through Anthropic
                </li>
              </ul>
            </div>

            {/* Self-hosted Options Card */}
            <div className="border border-white/20 rounded-2xl p-8">
              <div className="flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-white" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12 17a2 2 0 0 0 2-2 2 2 0 0 0-2-2 2 2 0 0 0-2 2 2 2 0 0 0 2 2m6-9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h1V6a5 5 0 0 1 5-5 5 5 0 0 1 5 5v2h1m-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3z"
                  />
                </svg>
              </div>
              <h4 className="text-xl font-bold text-white mb-6 text-center">
                Full Self-hosted Options
              </h4>
              <ul className="space-y-4">
                <li className="text-white flex items-start">
                  <span className="text-orange-light mr-4">•</span>
                  Reviews without your code touching Sourcery&apos;s servers
                </li>
                <li className="text-white flex items-start">
                  <span className="text-orange-light mr-4">•</span>
                  Use the LLM endpoints of your choice
                </li>
                <li className="text-white flex items-start">
                  <span className="text-orange-light mr-4">•</span>
                  Contact our team to learn more
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Seamless Integration Section */}
        <div className="flex flex-col items-center max-w-4xl mx-auto mb-16 px-4">
          <div className="w-full text-center mb-12">
            <h3 className="text-3xl font-bold text-white mb-6">
              Seamless Integration
            </h3>
            <p className="text-xl text-white leading-relaxed max-w-3xl mx-auto mb-6">
              Get instant reviews on every change in GitHub or GitLab or reviews
              in your IDE before your commit.
            </p>
          </div>

          {/* Integration Logos */}
          <div className="w-full mb-8">
            <IntegrationLogos />
          </div>
        </div>

        {/* Final CTA */}
        <div className="text-white text-center py-8 items-center">
          <h2 className="text-5xl font-bold mb-4">Try Sourcery Today</h2>
          <p className="mb-6">See what faster code reviews can do for you.</p>
          <div className="flex flex-col mx-auto pt-7 pb-8 items-center">
            <CTASection />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default IndexPage
