import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import PropTypes from "prop-types"
import SEO from "components/seo"
import FAQ from "../components/pricing/faq"
import ProductCard from "../components/pricing/product-card"
import SubFooter from "components/sub-footer"
import ClientsCarousel from "components/clients"
import { ArrowRightIcon } from "@primer/octicons-react"
import { Link } from "gatsby"

const CheckIcon = ({ fill }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_150_376)">
      <circle cx="12" cy="12.5" r="12" fill={fill || "#BDC1C7"} />
      <path d="M6 12.5L10 16.5L18 8.5" stroke="white" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_150_376">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

const CloseIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 11L1 1M1 11L11 1" stroke="#B9C0CB" strokeWidth="2" />
  </svg>
)

export default function Pricing() {
  const [annual, setAnnual] = React.useState(true)
  return (
    <>
      <SEO
        title="Sourcery | Pricing"
        description="Sourcery has plans for open source, professionals, and enterprises. Start using Sourcery for free today."
        keywords={[`sourcery`, `pricing`]}
      />
      {/* start header */}
      <div className="bg-black-500 py-8 bg-pricing">
        <div className="sm:pt-4 lg:pt-8 ">
          <div className="flex flex-col items-center mx-auto py-14 px-6">
            <h3 className="max-w-2xl text-3xl text-center text-white lg:text-6xl">
              Review and improve your code everywhere
            </h3>
          </div>
        </div>

        <div className="relative z-10 px-6 mx-auto max-w-screen-2xl">
          <div className="flex flex-wrap gap-4 items-center justify-center">
            <ProductCard
              forText="For open source projects"
              heading="Open Source"
              subHeading="Pro for open source projects"
              features={[]}
              link={`${process.env.SOURCERY_DASHBOARD_URL}?product=prod_opensource`}
              variant="gray"
              cta="Get Started"
              plusButton="Free to use for Open Source"
            >
              <p className="font-bold text-3xl">Free</p>
            </ProductCard>

            {/* second card */}
            <ProductCard
              forText="For all projects"
              heading="Pro"
              features={[
                "Code review for private repos",
                "Pull request summary",
                "Line by line review",
                "Custom review rules",
                "Team management",
              ]}
              link={`${process.env.SOURCERY_DASHBOARD_URL}?product=prod_pro${
                annual ? "&period=yearly" : "&period=monthly"
              }`}
              variant="orange"
              cta="Start 14 Day Free Trial"
              plusButton="Use for any project"
            >
              <div className="flex flex-col gap-4">
                <p className="font-bold text-3xl">
                  {annual ? "$10" : "$15"}
                  <span className="font-normal text-xl ml-2">/user/month</span>
                </p>
                <label className="flex items-center gap-3 cursor-pointer select-none">
                  <input
                    name="annual"
                    type="checkbox"
                    onChange={() => setAnnual(!annual)}
                    checked={annual}
                  />
                  Annual billing
                </label>
              </div>
            </ProductCard>

            {/* third card */}
            <ProductCard
              heading="Enterprise"
              subHeading="Pro for large enterprises"
              features={[
                "Self hosting option",
                "Priority support",
                "Customer success manager",
                "Invoice billing",
              ]}
              link="https://calendly.com/tim-gilboy/30min"
              variant="purple"
              cta="Contact us"
              plusButton="Includes everything in Pro"
            >
              <p className="font-bold text-3xl">Talk to us</p>
            </ProductCard>
          </div>
        </div>
      </div>
      {/* end header */}

      <div className="bg-black-500 pb-8 pt-16">
        <div className="max-w-screen-2xl mx-auto">
          <div className="bg-white py-20 lg:mx-[2.5rem] rounded-2xl">
            <div className="flex flex-col items-center justify-center">
              <p className="font-mono font-bold text-black-300 mb-2">
                <span className="text-orange-200">ls ~</span>/features
              </p>
              <h3 className="font-bold text-3xl text-center text-black-500">
                Compare features
              </h3>
            </div>
            {/* <!-- lg+ --> */}
            <div className="hidden lg:block">
              <table className="w-[90%] table-fixed my-14 mx-auto">
                <tbody>
                  <tr className="">
                    <th className="py-3 text-left"></th>
                    <th className="py-3 border-b border-black-100">
                      <div className="mx-auto">
                        <div className="flex justify-center">
                          <div
                            className={`font-bold text-base px-5 py-2 mb-5 rounded-full leading-7 bg-black-200`}
                          >
                            <p>Open Source</p>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="py-3 border-b border-black-100">
                      <div className="mx-auto">
                        <div className="flex justify-center">
                          <div
                            className={`font-bold text-base px-5 py-2 mb-5 rounded-full leading-7 bg-orange-200`}
                          >
                            <p>Pro</p>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="py-3 border-b border-black-100">
                      <div className="mx-auto">
                        <div className="flex justify-center">
                          <div
                            className={`font-bold text-base px-5 py-2 mb-5 rounded-full leading-7 bg-purple-200`}
                          >
                            <p>Enterprise</p>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr className="">
                    <td
                      className="text-base text-left border-t border-black-100 p-0"
                      scope="row"
                    >
                      <div className="inline-block py-5 text-base text-left border-t-2 border-black-500 z-20 -mt-1">
                        Code Review
                      </div>
                    </td>
                  </tr>
                  <tr className="border-t border-black-100">
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">GitHub reviews</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>For Open Source Code</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-t border-black-100">
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">GitLab Reviews</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>For Open Source Code</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">Reviews per Month</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>Unlimited on public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>Unlimited</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>Unlimited</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="w-[90%] table-fixed my-14 mx-auto">
                <tbody>
                  <tr className="">
                    <td
                      className="text-base text-left border-t border-black-100 p-0"
                      scope="row"
                    >
                      <div className="inline-block py-5 text-base text-left border-t-2 border-black-500 z-20 -mt-1">
                        Code improvements
                      </div>
                    </td>
                  </tr>
                  <tr className="border-t border-black-100">
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">In IDE Coding Assistant</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>For Open Source Code</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-t border-black-100">
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">Automated Fixes</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>For Open Source Code</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="border-t border-black-100">
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">Whole File Analysis</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>For Open Source Code</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th
                      className="py-5 text-xs text-left leading-[1.16rem]"
                      scope="row"
                    >
                      <p className="font-bold">Custom Rules</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>Unlimited</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>Unlimited</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-xs">
                        <p>Unlimited</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="w-[90%] table-fixed mb-8 mx-auto">
                <tbody>
                  <tr className="border-b border-black-100">
                    <td
                      className="inline-block py-5 text-base text-left border-t-2 border-black-500"
                      scope="row"
                    >
                      Integrations
                    </td>
                  </tr>

                  <tr className="font-bold  border-b border-black-100">
                    <th
                      className="py-5 text-base font-bold text-left "
                      scope="row"
                    >
                      <p className="text-xs">IDE Integrations</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>

                  <tr className="font-bold  border-b border-black-100">
                    <th className="text-base font-bold text-left " scope="row">
                      <p className="text-xs">GitHub Integration</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="font-bold  border-b border-black-100">
                    <th
                      className="py-5 text-base font-bold text-left "
                      scope="row"
                    >
                      <p className="text-xs">CLI</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="font-bold  border-b border-black-100">
                    <th
                      className="py-5 text-base font-bold text-left "
                      scope="row"
                    >
                      <p className="text-xs">CI/CD Integration</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="font-bold ">
                    <th
                      className="py-5 text-base font-bold text-left "
                      scope="row"
                    >
                      <p className="text-xs">Pre-commit Hook</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center font-mono font-bold text-base">
                        <p className="text-xs">Public repos</p>
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="w-[90%] table-fixed mb-8 mx-auto">
                <tbody>
                  <tr className="border-b border-black-100">
                    <td
                      className="inline-block py-5 text-base text-left border-t-2 border-black-500"
                      scope="row"
                    >
                      Billing and Support
                    </td>
                  </tr>

                  <tr className="font-bold  border-b border-black-100">
                    <th
                      className="py-5 text-base font-bold text-left "
                      scope="row"
                    >
                      <p className="text-xs">Team management</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CloseIcon />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#FFA13D" />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>

                  <tr className="font-bold  border-b border-black-100">
                    <th
                      className="py-5 text-base font-bold text-left "
                      scope="row"
                    >
                      <p className="text-xs">Invoice Billing</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CloseIcon />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CloseIcon />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>

                  <tr className="font-bold  border-b border-black-100">
                    <th className="text-base font-bold text-left " scope="row">
                      <p className="text-xs">Dedicated Account Manager</p>
                    </th>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CloseIcon />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CloseIcon />
                      </div>
                    </td>
                    <td className="px-6 py-5 ">
                      <div className="flex justify-center items-center">
                        <CheckIcon fill="#C985FF" />
                      </div>
                    </td>
                  </tr>
                  <tr className="font-bold">
                    <th
                      className="text-base font-bold text-left "
                      scope="row"
                    ></th>
                    <td className="px-6 py-5 ">
                      <Link to="/signup/?product=prod_opensource">
                        <button
                          className={`flex bg-black-200 justify-between items-center h-8 px-6 py-2 rounded-b-sm w-full hover:bg-opacity-90 `}
                        >
                          <p className="font-bold">Get started</p>
                          <ArrowRightIcon size={16} />
                        </button>
                      </Link>
                    </td>
                    <td className="px-6 py-5 ">
                      <Link to="/signup/?product=prod_pro">
                        <button
                          className={`flex bg-orange-200 justify-between items-center h-8 px-6 py-2 rounded-b-sm w-full hover:bg-opacity-90 `}
                        >
                          <p className="font-bold">Start Free Trial</p>
                          <ArrowRightIcon size={16} />
                        </button>
                      </Link>
                    </td>
                    <td className="px-6 py-5 ">
                      <OutboundLink href="https://calendly.com/tim-gilboy/30min">
                        <button
                          className={`flex bg-purple-200 justify-between items-center h-8 px-6 py-2 rounded-b-sm w-full hover:bg-opacity-90 `}
                        >
                          <p className="font-bold">Contact us</p>
                          <ArrowRightIcon size={16} />
                        </button>
                      </OutboundLink>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* mobile table */}
            <div className="px-5 bg-white lg:px-0 lg:hidden">
              <div className="px-5 py-5 mx-auto max-w-7xl  rounded-md lg:px-8">
                <div className="max-w-2xl mx-auto ">
                  <div className="mx-auto mt-5">
                    {/* free */}
                    <div className="flex justify-center">
                      <div
                        className={`font-bold text-base px-5 py-2 rounded-full leading-7 bg-black-200`}
                      >
                        <p>Open Source</p>
                      </div>
                    </div>
                  </div>
                  <section>
                    <table className="w-full mb-5">
                      <tbody>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Code Review
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Automated Fixes
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Whole File Analysis
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left "
                            scope="row"
                          >
                            Custom Rules
                          </th>
                          <td className="py-5">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <p className="text-xs">Unlimited</p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            IDE Integrations
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <p className="text-xs">Public repos</p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left "
                            scope="row"
                          >
                            GitHub Integration
                          </th>
                          <td className="">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <p className="text-xs">Public repos</p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            CLI
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <p className="text-xs">Public repos</p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Team management
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <CloseIcon />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Link to="/signup/?product=prod_opensource">
                      <button
                        className={`flex bg-black-200 justify-between items-center px-6 h-8 rounded-sm w-full hover:bg-opacity-90 `}
                      >
                        <p className="font-bold">Get started</p>
                        <ArrowRightIcon size={16} />
                      </button>
                    </Link>

                    {/* pro */}
                    <div className="mx-auto mt-8">
                      <div className="flex justify-center">
                        <div
                          className={`font-bold text-base px-5 py-2 rounded-full leading-7 bg-orange-200`}
                        >
                          <p>Pro</p>
                        </div>
                      </div>
                    </div>

                    <table className="w-full mb-5">
                      <tbody>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Code Review
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Automated Fixes
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Whole File Analysis
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left "
                            scope="row"
                          >
                            Custom Rules
                          </th>
                          <td className="py-5">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <p className="text-xs">Unlimited</p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            IDE Integrations
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            GitHub Integration
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            CLI
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Team management
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <CheckIcon fill="#FFA13D" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Link to="/signup/?product=prod_pro">
                      <button
                        className={`flex bg-orange-200 justify-between items-center px-6 h-8 rounded-sm w-full hover:bg-opacity-90 `}
                      >
                        <p className="font-bold">Start Free Trial</p>
                        <ArrowRightIcon size={16} />
                      </button>
                    </Link>

                    {/* team */}
                    <div className="mx-auto mt-8">
                      <div className="flex justify-center">
                        <div
                          className={`font-bold text-base px-5 py-2 rounded-full leading-7 bg-purple-200`}
                        >
                          <p>Enterprise</p>
                        </div>
                      </div>
                    </div>

                    <table className="w-full mb-5">
                      <tbody>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Code Review
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Automated Fixes
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Whole File Analysis
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left "
                            scope="row"
                          >
                            Custom Rules
                          </th>
                          <td className="py-5">
                            <div className="flex justify-center items-center font-mono font-bold text-base">
                              <p className="text-xs">Unlimited</p>
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            IDE Integrations
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            GitHub Integration
                          </th>
                          <td className="">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            CLI
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            CI/CD Integration
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Pre-commit Hook
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Team management
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Self hosted option
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Invoice Billing
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                        <tr className="border-b border-shily-lighter">
                          <th
                            className="py-5 text-xs font-bold font-mono text-left min-w-[240px] "
                            scope="row"
                          >
                            Dedicated Account Manager
                          </th>
                          <td className="py-5 px-6">
                            <div className="flex justify-center">
                              <CheckIcon fill="#C985FF" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <OutboundLink href="https://calendly.com/tim-gilboy/30min">
                      <button
                        className={`flex bg-purple-200 justify-between items-center px-6 h-8 rounded-sm w-full hover:bg-opacity-90 `}
                      >
                        <p className="font-bold">Contact us</p>
                        <ArrowRightIcon size={16} />
                      </button>
                    </OutboundLink>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6 bg-black-500">
        <div className="max-w-screen-2xl mx-auto opacity-80">
          <ClientsCarousel />
        </div>
      </div>

      <div className="bg-black-500 py-8 lg:pt-16">
        <div className="max-w-screen-2xl mx-auto">
          <div className="bg-white py-16 lg:py-20 lg:mx-[2.5rem] rounded-2xl bg-fac">
            <FAQ />
          </div>
        </div>
      </div>

      <SubFooter />
    </>
  )
}

CheckIcon.propTypes = {
  fill: PropTypes.string,
}
